@import "settings";
@import "_common/grid";

.default-page {
  background: #FCE8EB;

  @include respond-down(S){
    background: #FFF;
  }

  .white-container {
    max-width: 1330px;
    margin: 0 auto 220px auto;
    background: #FFF;
    padding: 100px 120px 20px 190px;

    @include respond-down(XL){
      padding: 90px 74px 20px 74px;
      margin-right: 13%;
      margin-left: 13%;
      margin-bottom: 156px;
    }
    @include respond-down(L){
      padding: 70px 100px 20px 100px;
      margin-right: 6%;
      margin-left: 6%;
      margin-bottom: 120px;
    }
    @include respond-down(M){
      padding: 50px 40px 10px 40px;
      margin-bottom: 80px;
      margin-right: 4%;
      margin-left: 4%;
    }
    @include respond-down(S){
      padding: 40px 0 0 0;
      margin-bottom: 40px;
      margin-right: 4%;
      margin-left: 4%;
    }

    .h1 {
      text-align: center;
      margin-bottom: 2em;
      line-height: 1.8em;
    }

    .h2 {
      &.attention {
        color: #E44554;
      }
    }

    .block {
      @include respond-property('margin-bottom', 100px, 70px, 60px, 52px, 52px, 48px);
    }

    .important {
      background: #EEE59A;
      padding: 5px;
      margin: -5px 0;
      font-weight: bold;
    }

    .selected {
      background: #E9F8F9;
      padding: 5px;
      margin: -5px 0;
      font-weight: bold;
      font-style: italic;
    }

    .actual {
      font-style: italic;
      color: #8D8888;
      text-align: center;
    }
  }
}

.error-page .body {
  background: #E9F8F9;
  height: 100vh;

  .header-box {
    position: relative;
    z-index: 33;
  }

  .image {
    max-width: unset;
    position: absolute;
    left: 50%;
    top: 190px;
    width: 1800px;
    margin-left: -1200px;

    @include respond-down(XL){
      width: 1500px;
      margin-left: -1000px;
    }
    @include respond-down(L){
      top: 100px;
    }
    @include respond-down(M){
      width: 1200px;
      margin-left: -900px;
    }
    @include respond-down(S){
      width: 900px;
      margin-left: -600px;
    }
    @include respond-down(XS){
      width: 700px;
      top: 120px;
      margin-left: -530px;
    }
  }

  .error-content {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1 100%;

    .code {
      @include respond-property('margin-top', 460px, 380px, 330px, 200px, 200px, 200px);
      font-size: 110px;
      @include respond-property('font-size', 150px, 130px, 120px);
      margin-bottom: 0.2em;
      color: #E93247;
    }

    .desc {
      @include respond-property('font-size', 30px, 25px, 20px, 20px, 20px, 18px);
      line-height: 1.4em;
      letter-spacing: 0.04em;
      color: #9DCDD1;
      margin-bottom: 1em;
      text-transform: uppercase;

      @include respond-down(XS){
        text-align: center;
      }
    }

    .button {
      margin: 0 0 150px 0;

      @include respond-down(L){
        margin: 0 0 100px 0;
      }
    }
  }
}
